import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './header'
import Footer from './footer'

export default ({title, description, image, children}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title ? `${title} | ` : ``}
          antisound.net
        </title>
        <meta name="description" content={description ? description : 'The musical projects and releases of Skye Klein, including Terminal Sound System, HALO, A Beautiful Machine and others.'} />
        <meta property="og:image" content={image ? `https://antisound.net${image}` : `https://antisound.net/images/og.jpg`} />
        <link rel="stylesheet" href="/styles.css" />
      </Helmet>
      
      <Header
      />

      {children && (
        <main className="main">
          <div className="container">
            {children}
          </div>
        </main>
      )}

      <Footer
      />
    </>
  )
}
