import React from 'react'
import { Link } from 'gatsby'

export default () => {
  return (
    <header>
      <div className="container">
        <nav>
          <Link to="/">
            antisound.net
          </Link>
          <Link to="/projects">
            Projects
          </Link>
          <Link to="/releases">
            Releases
          </Link>
          <Link to="/studiologs">
            Studiologs
          </Link>
          <Link to="/about">
            About
          </Link>
        </nav>
      </div>
    </header>
  )
}
